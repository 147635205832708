import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ServicesPage = () => (
  <Layout>
    <SEO title="Consulting Services in London" />
    <h1 className="title">Consulting Services</h1>
    <hr />
    <div className="tile is-ancestor">
      <div className="tile is-parent">
        <article className="tile is-child notification is-dark">
          <p className="title">Your business</p>
          <div className="content" />
        </article>
      </div>
    </div>
    <div className="tile is-ancestor">
      <div className="tile is-parent">
        <article className="tile is-child notification is-light">
          <p className="title">Digital Transformation</p>
          <div className="content" />
        </article>
      </div>
    </div>
    <div className="tile is-ancestor">
      <div className="tile is-vertical is-8">
        <div className="tile">
          <div className="tile is-parent is-vertical">
            <article className="tile is-child notification is-light">
              <p className="title">Design Systems</p>
              <div className="content" />
            </article>
          </div>
          <div className="tile is-parent is-vertical">
            <article className="tile is-child notification is-light">
              <p className="title">Micro services</p>
              <div className="content" />
            </article>
            <article className="tile is-child notification is-light">
              <p className="title">Integrations</p>
              <div className="content" />
            </article>
          </div>
        </div>
      </div>
      <div className="tile is-parent is-vertical">
        <article className="tile is-child notification is-light">
          <div className="content">
            <p className="title">Architecture solutions</p>
            <div className="content" />
          </div>
        </article>
        <article className="tile is-child notification is-light">
          <div className="content">
            <p className="title">Process optimisation</p>
            <div className="content" />
          </div>
        </article>
      </div>
    </div>
  </Layout>
);

export default ServicesPage;
